// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/login.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/login.tsx");
  import.meta.hot.lastModified = "1724962854544.6423";
}
// REMIX HMR END

import { Form, Link, useActionData, useNavigation } from "@remix-run/react";
import classNames from "classnames";
import { Error } from "~/components/error";
import { Spinner } from "~/components/spinner";
import { createUserSession, login } from "~/models/session.server";
import { badRequest, validateEmail, validateLoginPassword } from "../utils";
export const meta = () => {
  return [{
    title: "Login | Feedgenie"
  }];
};
export let headers = () => {
  return {
    "Cache-Control": "public, max-age=86400, s-maxage=259200"
  };
};
export const action = async ({
  request
}) => {
  const form = await request.formData();
  const email = form.get("email");
  const password = form.get("password");
  if (typeof email !== "string" || typeof password !== "string") {
    return badRequest({
      formError: "Form not submitted correctly."
    });
  }
  const fields = {
    email,
    password
  };
  const fieldErrors = {
    email: validateEmail(email),
    password: validateLoginPassword(password)
  };
  if (Object.values(fieldErrors).some(Boolean)) {
    return badRequest({
      fieldErrors,
      fields
    });
  }
  const user = await login(fields);
  if (!user) {
    return badRequest({
      fields,
      formError: "Something went wrong. Please try again."
    });
  }
  if ("detail" in user) {
    return badRequest({
      fields,
      formError: "Wrong credentials."
    });
  }
  return createUserSession(user.access_token, "/dashboard");
};
export default function Login() {
  _s();
  const actionData = useActionData();
  const navigation = useNavigation();
  const loading = ["loading", "submitting"].includes(navigation.state);
  return <div className="mx-auto mt-6 flex flex-col items-center px-6 md:mt-20">
      <div className="w-full rounded-lg bg-white shadow dark:bg-feedgenie-8 md:w-1/3">
        <div className="p-8 md:p-32">
          <img src="/images/logo-large.svg" alt="feedgenie logo 2" className="mx-auto mb-14" width={220} height={155} />

          <Form method="post">
            <div className="mb-4">
              <input name="email" id="email" className="block w-full rounded-lg bg-gray-50 p-4 text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400" placeholder="email@example.com" defaultValue={actionData?.fields?.email} />

              {actionData?.fieldErrors?.email && <Error message={actionData.fieldErrors.email} />}
            </div>

            <div className="mb-4">
              <input type="password" name="password" id="password" placeholder="••••••••" className="block w-full rounded-lg bg-gray-50 p-4 text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400" defaultValue={actionData?.fields?.password} />

              {actionData?.fieldErrors?.password && <Error message={actionData.fieldErrors.password} />}
            </div>

            <div className="mb-4 flex items-center justify-between">
              <Link to="/request-reset" prefetch="intent" className="text-sm font-medium text-white hover:underline">
                Forgot password?
              </Link>
            </div>

            {actionData?.formError ? <Error className="mb-4" message={actionData.formError} /> : null}

            <button type="submit" className={classNames("mb-2 flex w-full items-center justify-center rounded-lg p-4 text-sm font-medium", {
            "bg-white hover:bg-feedgenie-3 hover:text-white": !loading,
            "bg-gray-200 text-gray-500": loading
          })} disabled={loading}>
              {loading ? <Spinner /> : <>Sign in</>}
            </button>

            <button className="w-full rounded-lg p-4 text-center text-sm font-medium text-white">
              <Link to="/signup" prefetch="intent">
                Sign up
              </Link>
            </button>
          </Form>
        </div>
      </div>
    </div>;
}
_s(Login, "e3rMULficn7ldQYYArv00m53mwQ=", false, function () {
  return [useActionData, useNavigation];
});
_c = Login;
var _c;
$RefreshReg$(_c, "Login");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;